import styled from "styled-components";
import * as colors from "../../../styles/colors";

export const Container = styled.div`

width: 100%;
min-height: 100vh;
max-height:160vh;
background:linear-gradient(${colors.Background2});
overflow-x: hidden;

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');

h2{
    margin-left: 3%;
    padding: 5% 15% 0% 15%;
    font-family: 'Akshar', sans-serif;
    font-size: 24px;
    line-height: 26,4px;
    text-align: center;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
    text-align: left;
    margin-top: 5%;
}

.title{
    padding-top: 3%;
    display: flex;
    justify-content: center;
}

.first{
    border: ${colors.Primary0} solid;
    width: 70%;
    margin: 5% auto;
    border-radius: 15px;
    border-width: 1.5px;
    background-color: rgba(112, 112, 112, 0.5);
}

.quadroOne{
    display: flex;
    justify-content: space-evenly;
    padding: 5% 12% 2.5% 2.5%;
    align-items: center;
}

.quadroOne p{
    color: ${colors.Light};
    font-size: 16px;
    line-height: 17.6px;
    font-family: 'Akshar', sans-serif;
    font-weight: 700;
}

.stars{
    position: relative;
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: space-evenly;
    padding: 0 90px;
}

.quadroOne img{
    width: 25px;
    height: 25px;
}

.comentOne{
    padding: 10px 25px;
    color: ${colors.Light};
    font-size: 12px;
    line-height: 15.53px;
    font-family: 'PT Sans', sans-serif;
}

.uppercase{
    text-transform: uppercase;
}

.second h3{
    padding: 0% 15%;
    font-family: 'Akshar', sans-serif;
    font-size: 24px;
    line-height: 26.4px;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
    text-align: left;
    margin-top: 10%;
}

.second p{
    padding: 3% 15%;
    color: ${colors.Light};
    font-size: 16px;
    line-height: 20.7px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 400;
    text-align: left;
    margin-bottom: 5%;
}

.mapa{
    width: 100%;
    height: 10%;
    display:flex;
    justify-content:center;
}
`