import styled from "styled-components";
import * as colors from "../../styles/colors";

export const Container= styled.div`
width:100%;
@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');
height:60vh;


h2{
    font-family: 'Akshar', sans-serif;
    font-size: 2rem;
    line-height: 22px;
    text-align: center;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
    height:5vh;
    width:100%;
    text-align:center;
    padding: 5% 0%;

}
div{
    width:100%;
    display:flex;
    justify-content:center;
}
.control-prev, .control-next{
    background-color: black !important; 
}
iframe{
    height:42vh;
}
`