import React from "react";
import {Container} from './style';
import facebook from "../../../assets/icons/facebook.svg";
import instagram from "../../../assets/icons/instagram.svg";
import web from "../../../assets/icons/web.svg";

function QuestionAnswers(){
    
    return(
        <Container>
            <div className="title">
            <h2>Perguntas frequentes</h2>
            </div>
            <div className="Perguntas">
                <p className="Question">Qual o preço do corte?</p>
                <p className="Answer">R$ 360,00</p>
                <p className="Question">Valor da transformação</p>
                <p className="Answer">Esse valor depende daquilo que você vai precisar. Antes de tudo, você passará por uma avaliação completa no cabelo para que possamos verificar o que seu cabelo precisa realmente e te passar exatamente o que você precisa para deixá-lo ainda mais incrível! </p>
                <p className="Question">Você parcela?</p>
                <p className="Answer">Sim, o preço pode ser parcelado em até 3 vezes sem juros</p>
            </div>
            <div className="Icons">
                <a href="https://www.facebook.com/NellCarmoHairStylist"><img src={facebook} alt="logo do facebook"></img></a>
                <a href="https://www.instagram.com/nellcarmo/"><img src={instagram} alt="logo do instagram"></img></a>
                <a href="https://nellcarmohair.com.br/loja.html"><img src={web} alt="logo de globo representando web"></img></a>
            </div>
        </Container>
    )
}

export default QuestionAnswers;