import styled from "styled-components";
import * as colors from "../../../styles/colors";

export const Container = styled.div`

width:100%;
height:100vh;
background: linear-gradient(${colors.Background1});
display:flex;
flex-direction:column;


@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');


h2{
    font-family: 'Akshar', sans-serif;
    font-size:2rem;
    line-height:22px;
    padding:10px;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
    margin:2% 0% 2% 0%;

}
.Video-title {
    display:flex;
    flex-direction:column;
    justify-items:center;
    align-items:center;
    height:50%;

}

.Itens{
    display:flex;
    flex-direction:row;
    justify-content:space-between;
    align-items:center;
    width:100%;
    height:40%;
    margin:0 auto;
}
.Descricao{
    width:25%;
    height:15%;
    display:flex;
    flex-direction:column;
    margin:2%;
}
.Image-text{
    width:100%;
    height:100%;
    justify-content:center;
    align-items:center;
    display:flex;
    flex-direction:row;
    margin-bottom:20%;
}
h3{
    font-family: 'Akshar', sans-serif;
    font-size:1.4rem;
    line-height:1.5rem;
    margin-left:3%;
    color:${colors.Primary0};
    text-align:center;
}
p{
    font-family: 'PT Sans', sans-serif;
    color:white;
    text-align:center;
    line-height:1.2rem;
    font-size:1rem;
}

.Image-text img{
    width:40%;
}
`
export const Content = styled.div`
width:1100px;
height:100vh;
margin:0 auto;
`