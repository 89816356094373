import styled from "styled-components";
import * as colors from "../../../styles/colors";
import bandeira from "../../../assets/Atualizado/fundo_dourado.svg";
export const Container = styled.div`

width: 100%;
height: 100vh;
background:linear-gradient(${colors.Background1});
overflow-x: hidden;

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');

`

export const Content = styled.div`
width:1100px;
height:100vh;
display:flex;
flex-direction:column;
margin:0 auto;

strong{
    font-family: 'Akshar', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 44px;
    text-align: center;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
}

p{
    color: ${colors.Light};
    font-family: 'PT Sans', sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: center;
}

h2{
    color: ${colors.Light};
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
    font-size: 2rem;
    line-height: 1.5rem;
    text-align: center;
    margin-bottom:5.5%;
}

li{
    color: ${colors.Light};
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
    font-size: 1.5rem;
    line-height: 1.5rem;
    text-align: left;
}

ul{
    list-style:inside;
}

.bandeira{
    margin:0 auto;
    background-image:url(${bandeira});
    background-position:center;
    background-repeat:no-repeat;
    background-size:cover;
    width:376.2px;
    height:333px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-bottom:5.5%;
    padding-bottom:7%;
}


.lista{
    z-index:20;
    position:absolute;
}
.description{
    width: 100%;
    min-height:20%;
    max-height:45%;
    padding: 2% 2% 2% 2%;
    background-color: rgba(53 ,53 ,53 , 0.5);
    border: 2px solid ${colors.Primary0};
    border-radius:20px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-bottom:3%;
}



`