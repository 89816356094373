import styled from "styled-components";
import * as colors from "../../../styles/colors";

export const Container=styled.div`

width:100%;
height:70vh;
background:linear-gradient(${colors.Background2});
display:flex;
flex-direction:column;

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');


.AtendimentoPergunta{
    display:flex;
    flex-direction:row;
    width:80%;
    height:100%;
    justify-content:left;
    align-items: center;

}
h2{
    width:1100px;
    padding: 0.25%;
    font-family: 'Akshar', sans-serif;    
    font-size: 2rem;
    line-height: 3rem;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
    text-align: left;
    margin:1% auto;

}   

.mapa-legenda{
    display:flex;
    flex-direction:column;
    width:70%;
}

.titulomapa
{
    padding: 1% 1.5%;
    color: ${colors.Light};
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-family: 'PT Sans', sans-serif;
    font-weight:400; 
}
.Icons{
display:flex;
flex-direction:row;
position:relative;
top:52vh;
height:5%;

}
img{
    width:40px;
    height:40px;
}
a{
    margin-right:10%;
}
iframe{
    width:100%;
}
`
export const Content = styled.div`
width:1100px;
height:70vh;
margin:0 auto;
display:flex;
flex-direction:row;



`