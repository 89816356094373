import React from "react";
import { Container, Content } from "./style";
import SliderCarousel from "../../../components/ImageCarousel";

function DepoimentosWeb() {
  function openLink(){
    return window.open('https://wa.me/5511964477939')
  }

  return (
    <>
      <Container id="DepoimentosWeb">
        <Content>
          <div className="Carousel">
          <SliderCarousel/>
          </div>
          <div className="btn">
            <button type="button" onClick={()=>openLink()}>Agende um horário</button>
            </div>
        </Content>
      </Container>
    </>
  );
}

export default DepoimentosWeb;
