import React from "react";
import { Container, Content } from "./style";


function WorkWeb2() {
  return (
    <Container>
      <Content>
        <div>
          <h2>Tenho formação nacional e internacional nas academias:</h2>
        </div>
        <div className="bandeira">
            <ul>
              <li>Llongueras, na Argentina</li>
              <li>Tony&Guy, na Inglaterra</li>
              <li>Pivot Point, no Brasil</li>
              <li>Mahogany, no Brasil</li>
            </ul>
          </div>
        <div className="description">
          <p>
            Em toda minha carreira como Hairstylist profissional, já participei
            de várias premiações e recebi diversos prêmios. Além de tudo isso,
            recentemente participei do
            <strong> Gold Scissors Award</strong>, um prêmio de relevância
            internacional. Entre os inúmeros critérios para participar desta
            premiação está a relevância do trabalho e a trajetória de cada
            profissional.
          </p>
        </div>
      </Content>
    </Container>
  );
}

export default WorkWeb2;
