import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Container } from './style';


function SliderCarouselVideoWeb (){

return(
<>
<Container>
<h2>Conheça um pouco do meu trabalho</h2>
<Carousel preventMovementUntilSwipeScrollTolerance={true} showThumbs={false} showStatus={false} width={800}>
    <div>
    <iframe width="1080" height="1350" src="https://www.youtube.com/embed/e2grzMPS4SE"
     title="Video de descrição 1" 
     frameborder="0" 
     allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
     allowfullscreen></iframe>
    </div>
    <div>
    <iframe width="1080" height="1350" src="https://www.youtube.com/embed/8oP7zXo3uxs"
     title="Video de descrição 2" 
     frameborder="0" 
     allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
     allowfullscreen></iframe>
    </div>
</Carousel>
</Container>
</>
);
}
export default SliderCarouselVideoWeb;