import React, {useState} from "react";
import GlobalStyle from "./styles/GlobalStyle";
import HomeMobile from "./pages/mobile/home";
import StoryMobile from "./pages/mobile/story";
import WorkMobile from "./pages/mobile/work";
import SliderCarouselVideo from "./components/VideoCarousel";
import DepoimentosMobile from "./pages/mobile/depoimentos";
import QuestionAnswers from "./pages/mobile/questionAnswers";
import HomeWeb from "./pages/web/homeWeb";
import WorkWeb from "./pages/web/workWeb";
import StoryWeb from "./pages/web/storyWeb";
import GalleryWeb from "./pages/web/galleryWeb";
import WorkWeb2 from "./pages/web/workWeb2";
import DepoimentsWeb from "./pages/web/depoiments";
import QuestionAnswerWeb from "./pages/web/questionAnswerWeb";

function App() {
  const [larguraState, setLarguraStatus] = useState(window.innerWidth);

 window.addEventListener('resize',()=>{
  setLarguraStatus(window.innerWidth);
 });

  if (larguraState > 1100) {
    return(
      <>
      <GlobalStyle/>
      <HomeWeb/>
      <StoryWeb/>
      <WorkWeb/>
      <WorkWeb2/>
      <GalleryWeb/>
      <DepoimentsWeb/>
      <QuestionAnswerWeb/>
      </>
    )
  }else if (larguraState <=1100)
    return(
      <>
      <GlobalStyle/>
      <HomeMobile/>
      <StoryMobile/>
      <SliderCarouselVideo/>
      <WorkMobile/>
      <DepoimentosMobile/>
      <QuestionAnswers/>
      </>
    )
  ;
}

export default App;
