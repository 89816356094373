import React from "react";
import { Container, Content } from "./style";
import facebook from "../../../assets/icons/facebook.svg";
import instagram from "../../../assets/icons/instagram.svg";
import web from "../../../assets/icons/web.svg";

function QuestionAnswerWeb() {
  return (
    <Container>

      <Content>
      <div className="AtendimentoPergunta">
        <div className="mapa-legenda">
          <h2>Meu atendimento é realizado no endereço:</h2>
          <p className="titulomapa">R. Cel. Diogo, 364 - Aclimação, São Paulo - SP, 01545-000</p>
          <div className="mapa">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1828.3598449391761!2d-46.62434805781025!3d-23.578508858687577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce597c842efae9%3A0x49a8fc350c81749a!2sR.%20Cel.%20Diogo%2C%20364%20-%20Jardim%20da%20Gloria%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2001545-000!5e0!3m2!1spt-BR!2sbr!4v1654003592691!5m2!1spt-BR!2sbr"
              width="500"
              height="300"
              frameBorder="0"
              style={{ border: 0 }}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
              title="Mapa de localizacao do atendimento Nell Carmo"
            />
          </div>
        </div>
      </div>
      <div className="Icons">
        <a href="https://www.facebook.com/NellCarmoHairStylist" >
          <img src={facebook} alt="logo do facebook"></img>
        </a>
        <a href="https://www.instagram.com/nellcarmo/" >
          <img src={instagram} alt="logo do instagram"></img>
        </a>
        <a href="https://nellcarmohair.com.br/loja.html" >
          <img src={web} alt="logo de globo representando web"></img>
        </a>
      </div>
      </Content>
    </Container>
  );
}

export default QuestionAnswerWeb;
