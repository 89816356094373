import { createGlobalStyle } from "styled-components";
import * as colors from "./colors";

export default createGlobalStyle`

*{
    margin:0;
    padding:0;
    outline:none;
    box-sizing:border-box;
    scroll-behavior:smooth;
}

body{


}


html{
    height:100%;
}

button{
    cursor: pointer;
}

a{
    text-decoration:none;
}

ul{
    list-style:none;
}

.teste{
    height: 100vh;
    width: 100%;
    background: linear-gradient(${colors.Background2});
}

p::selection{
    background:${colors.Primary0};
}

`