import styled from "styled-components";
import * as colors from "../../../styles/colors";

export const Container = styled.div`

width: 100%;
height: 100vh;
background:linear-gradient(${colors.Background2});
overflow-x: hidden;

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');

h2{

    padding: 0 30px;
    font-family: 'Akshar', sans-serif;    
    font-size: 2rem;
    line-height: 22px;
    text-align: center;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
    text-align: left;
    margin-left:3.5%;
}

.Depoiments{
    height:30%
}
.Depoiments-Organizer{
    display:flex;
    flex-direction:row;
    
}
.first{
    border: ${colors.Primary0} solid;
    width: 45%;
    margin: 2% auto;
    border-radius: 10px;
    border-width: 1.5px;
    background-color: rgba(112, 112, 112, 0.5);
}

.quadroOne{
    display: flex;
    flex-direction: row;
    padding: 3% 12% 2.5% 2.5%;
    align-items: center;
}

.quadroOne p{
    color: ${colors.Light};
    font-size: 18px;
    line-height: 17.6px;
    font-family: 'Akshar', sans-serif;
    font-weight: 700;
}

.quadroOne img{
    width: 25px;
    height: 25px;
    margin:0 3% 0 7%;
}

.comentOne{
    padding: 10px 25px;
    color: ${colors.Light};
    font-size: 13px;
    line-height: 15.53px;
    font-family: 'PT Sans', sans-serif;
}

.uppercase{
    text-transform: uppercase;
}

.second h3{
    padding: 0 30px;
    font-family: 'Akshar', sans-serif;
    font-size: 16px;
    line-height: 17.6px;
    text-align: center;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
    margin-top: 10%;
}

.second p{
    padding: 10px 25px;
    color: ${colors.Light};
    font-size: 12px;
    line-height: 15.53px;
    font-family: 'PT Sans', sans-serif;
    font-weight: 400;
    text-align: center;
}

.Carousel{
    display:flex;
    align-items:center;
    justify-content:center;
    width:80%;
    height:80%;
    margin:auto;
    
}

.btn{
    width:100%;
    height: 7%;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:10%;
}

button{
    background: linear-gradient(${colors.Gradient});
    border: none;
    color: ${colors.Light};
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 17.6px;
    padding: 14px 45px;
    border-radius: 5px;
    box-shadow: 7px 7px 15px rgba(0,0,0,0.25);
}
`
export const Content = styled.div`
width:1100px;
height:80vh;
margin:0 auto;
`