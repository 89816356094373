import React from "react";
import { Container } from './style';
import Star from "../../../assets/icons/estrela.svg"


function DepoimentosMobile() {

    return (
        <>
            <Container id='DepoimentosMobile'>
                <div className="title">
                <h2>Depoimentos</h2>
                </div>
                <div className="first">
                    <div className="quadroOne">
                        <p>Gisele</p>

                        <img src={Star} alt="desenho de uma estrela dourada"></img>
                        <img src={Star} alt="desenho de uma estrela dourada"></img>
                        <img src={Star} alt="desenho de uma estrela dourada"></img>
                        <img src={Star} alt="desenho de uma estrela dourada"></img>
                        <img src={Star} alt="desenho de uma estrela dourada"></img>
                    </div>

                    <div className="comentOne">
                        <p>“Um amor em forma de cabeleleiro.
                            Pensa num ser humano simpático e muito humilde.
                            Estou feliz, pois nas mãoes dele fiz minha transformação. A equipe é maravilhosa, Rafael muito bonzinho fui muito bem receida, secretária dele muito simpática, salão maravilhoso ambiente agradável.
                            Super recomendo, agora que encontrei o Nell não troco mais.”</p>
                    </div>
                </div>

                <div className="first">
                    <div className="quadroOne">
                        <p>Suely</p>

                        <img src={Star} alt="desenho de uma estrela dourada"></img>
                        <img src={Star} alt="desenho de uma estrela dourada"></img>
                        <img src={Star} alt="desenho de uma estrela dourada"></img>
                        <img src={Star} alt="desenho de uma estrela dourada"></img>
                        <img src={Star} alt="desenho de uma estrela dourada"></img>
                    </div>

                    <div className="comentOne">
                        <p className="uppercase">“amo o nell carmo e todos da equipe dele, já é a quarta vez que vou, desta última vez levei minha mãe, irmã e filha.”</p>
                    </div>
                </div>

                <div className="second">
                    <h3>Meu atendimento é realizado no endereço:</h3>
                    <p>R. Cel. Diogo, 364 - Aclimação, São Paulo - SP, 01545-000</p>
                    <div className="mapa">
                        <iframe
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1828.3598449391761!2d-46.62434805781025!3d-23.578508858687577!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94ce597c842efae9%3A0x49a8fc350c81749a!2sR.%20Cel.%20Diogo%2C%20364%20-%20Jardim%20da%20Gloria%2C%20S%C3%A3o%20Paulo%20-%20SP%2C%2001545-000!5e0!3m2!1spt-BR!2sbr!4v1654003592691!5m2!1spt-BR!2sbr"
                            width="600"
                            height="300"
                            frameBorder="0"
                            style={{ border: 0 }}
                            allowFullScreen=""
                            aria-hidden="false"
                            tabIndex="0"
                            title="Localização de atendimento nell carmo"
                        />
                    </div>
                </div>
            </Container>
        </>
    )
}

export default DepoimentosMobile;
