import styled from "styled-components";
import * as colors from "../../../styles/colors";
import Bandeira from '../../../assets/Branding/fundo_dourado.svg'


export const Container = styled.div`
width: 100%;
min-height: 100vh;
max-height:250vh;
background: linear-gradient(${colors.Background1});
overflow-x: hidden;

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');

.bandeira{
    background-image: url(${Bandeira});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    min-height:70%;
    max-height: 20%;
    width:70%;
    margin: 0% 15% 0% 15%;
    padding: 12% 3% 35% 3%;
}

.curriculo{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.curriculo h2{
    color: ${colors.Light};
    padding: 7% 15% 5% 15%;
    font-size: 16px;
    line-height: 17.6px;
    text-align: center;
    font-family: 'Akshar', sans-serif;
    margin-top: 5%;
}

.curriculo p{
    padding: 5% 0% 3% 0%;
    font-size: 16px;
    line-height: 20,7px;
    text-align: center;
    font-family: 'PT Sans', sans-serif;
}

.lista{
    position: relative;
    display: flex;
    width: 100%;
    height: 50%;
    justify-content: space-evenly;
    padding: 0 30px;
}

.lista ul{
    padding-top: 10px;
}

.lista li{
    list-style: circle;
    color: ${colors.Light};
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    line-height: 20,7;
}

.textgold p{
    color: ${colors.Light};
    padding: 25px 40px;
    padding: 5% 15% 5% 15%;
    font-size: 16px;
    line-height: 20.7px;
    text-align: center;
    font-family: 'PT Sans', sans-serif;
    margin-bottom: 5%;
}

.textgold p strong{
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
    font-weight: 700;
    font-size: 20px;
    line-height: 22px;
}

.btn{
    width:100%;
    height: 10%;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top: 5%;
}

button{
    background: linear-gradient(${colors.Gradient});
    border: none;
    color: ${colors.Light};
    font-family: 'Akshar', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 17.6px;
    padding: 14px 45px;
    border-radius: 5px;
}
`