import styled from "styled-components";
import * as colors from "../../../styles/colors";

export const Container = styled.div`

width: 100%;
min-height: 100vh;
max-height:250vh;
background:linear-gradient(${colors.Background2});
overflow-x: hidden;

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');

.head img{
    width: 100%;
}

.logo img{
    display: block;
    margin: 0 auto;
    width: 10%;
    height: 10%;
}

.titulo{
    padding: 8% 15% 0% 15%;
    width: 100%;
    text-transform: uppercase;
    font-family: 'Akshar', sans-serif;
    font-size: 24px;
    line-height: 26.4px;
    text-align: center;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
}

.textOne{
    padding: 5% 15% 3% 15%;
    width: 100%;
    color: ${colors.Light};
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    line-height: 20.7px;
    text-align: center;
}

.nellcarmo{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50%;
}
.nellcarmo img{
    padding-top: 5%;
    width: 70%;
    height: 60%;
}

.botao{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3% 15% 0% 15%;
}

button{
    background: linear-gradient(${colors.Gradient});
    border: none;
    color: ${colors.Light};
    font-family: 'Akshar', sans-serif;
    font-weight: 700;
    font-size: 16px;
    line-height: 17.6px;
    padding: 14px 45px;
    border-radius: 5px;
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
}
`