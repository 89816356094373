import React from "react";
import {Container, Content} from "./style";
import Star from "../../../assets/icons/estrela.svg"
function DepoimentsWeb (){
    return(
        <Container>
            <Content>
            <h2>Depoimentos</h2>
                <div className="Depoiments">
          <div className="Depoiments-Organizer">
            <div className="first">
              <div className="quadroOne">
                <p className="pessoa">Gisele</p>
                <img src={Star} alt="desenho de uma estrela dourada"></img>
                <img src={Star} alt="desenho de uma estrela dourada"></img>
                <img src={Star} alt="desenho de uma estrela dourada"></img>
                <img src={Star} alt="desenho de uma estrela dourada"></img>
                <img src={Star} alt="desenho de uma estrela dourada"></img>
              </div>
              <div className="comentOne">
                <p>
                  “Um amor em forma de cabeleleiro. Pensa num ser humano
                  simpático e muito humilde. Estou feliz, pois nas mãoes dele
                  fiz minha transformação. A equipe é maravilhosa, Rafael muito
                  bonzinho fui muito bem receida, secretária dele muito
                  simpática, salão maravilhoso ambiente agradável. Super
                  recomendo, agora que encontrei o Nell não troco mais.”
                </p>
              </div>
            </div>
            <div className="second">
                <div className="quadroOne">
                    <p className="pessoa">Suely</p>
                    <img src={Star} alt="desenho de uma estrela dourada"></img>
                    <img src={Star} alt="desenho de uma estrela dourada"></img>
                    <img src={Star} alt="desenho de uma estrela dourada"></img>
                    <img src={Star} alt="desenho de uma estrela dourada"></img>
                    <img src={Star} alt="desenho de uma estrela dourada"></img>
                </div>
              <div className="comentOne">
                <p className="uppercase">
                  “amo o nell carmo e todos da equipe dele, já é a quarta vez
                  que vou, desta última vez levei minha mãe, irmã e filha.”
                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="BoxPerguntas">   
            <h2>Perguntas frequentes</h2>
          <div className="Perguntas">
            <div className="leftQuestion">
            <p className="Question">Qual o preço do corte?</p>
            <p className="Answer">R$ 360,00</p>
            <p className="Question">Você parcela?</p>
            <p className="Answer">
              Sim, o preço pode ser parcelado em até 3 vezes sem juros
            </p>
            </div>
            <div className="rightQuestion">
            <p className="Question">Valor da transformação</p>
            <p className="Answer">
              Esse valor depende daquilo que você vai precisar. Antes de tudo,
              você passará por uma avaliação completa no cabelo para que
              possamos verificar o que seu cabelo precisa realmente e te passar
              exatamente o que você precisa para deixá-lo ainda mais incrível!{" "}
            </p>
            </div>
          </div>
        </div>

            </Content>
        </Container>
    )
}
export default DepoimentsWeb;
