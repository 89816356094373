import React from "react";
import { Container } from './style';
import headLogo from "../../../assets/Branding/ondaHead.svg"
import Logo from "../../../assets/Atualizado/logo.webp"
import NellCarmo from "../../../assets/Atualizado/Nell_traco.webp"

function HomeMobile() {
    function openLink() {
        return window.open('https://wa.me/5511964477939')
    }

    return (
        <>
            <Container id='HomeMobile'>
                <div className="head">
                    <img src={headLogo} alt="Onda dourada no topo da página"></img>
                </div>
                <div className="logo">
                    <img src={Logo} alt="imagem teste"></img>
                </div>
                <div>
                    <h1 className="titulo">A transformação que você procura está aqui!</h1>
                    <p className="textOne">Sou o <strong>Nell Carmo, Hairstylist profissional</strong> e chegou a sua hora de ser transformada!</p>
                </div>
                <div className="botao">
                    <button type="button" onClick={() => openLink()}>Agende um horário</button>
                </div>
                <div className="nellcarmo">
                    <img src={NellCarmo} alt="Foto do Nell Carmo segurando uma tesoura apontada para a câmera"></img>
                </div>
            </Container>
        </>
    )
}

export default HomeMobile