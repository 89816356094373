import styled from "styled-components";
import * as colors from "../../../styles/colors";

export const Container = styled.div`

width: 100%;
height:100vh;
background:linear-gradient(${colors.Background2});
overflow-x: hidden;


`
export const Content = styled.div`

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');
width:1100px;
height:80vh;
display:flex;
flex-direction:column;
justify-items:center;
align-items:center;
margin:0 auto;

.ImagemLogo{
display:flex;
flex-direction:row;
height:30vh;
margin-top:1%;
}

.Logo{
height:110px;
width:111.5px;;
}

.Nell{
height:100%;
}

.Texto-button{
align-items:center;
margin-left:1.5%;
margin-right:1.5%;
display:flex;
height:55vh;
}

h1{
    margin-bottom:5%;
    margin-left:10%;
    width: 100%;
    text-transform: uppercase;
    font-family: 'Akshar', sans-serif;
    font-weight: 700;
    font-size: 2.5rem;
    line-height: 55px;
    text-align: center;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
}

p{
    padding: 0 7%;
    color: ${colors.Light};
    font-family: 'PT Sans', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 1.8rem;
    text-align: center;
}
.btn{
    width:100%;
    display:flex;
    align-items:center;
    justify-content:center;
    margin-top:10%;
}
button{
    background: linear-gradient(${colors.Gradient});
    border: none;
    color: ${colors.Light};
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 17.6px;
    padding: 13px 45px;
    border-radius: 5px;
    z-index: 10;
    box-shadow: 7px 7px 15px rgba(0, 0, 0, 0.25);
}
`