import React from "react";

import { Container, Content } from './style';
import onda from '../../../assets/Atualizado/tarja_dourada.svg';
import logo from '../../../assets/Atualizado/logo.webp';
import Nell from '../../../assets/Atualizado/Nell_traco.webp';

function HomeWeb() {
    function openLink() {
        return window.open('https://wa.me/5511964477939')
    }

    return (
        <>
            <Container id='HomeWeb'>
                <img src={onda} alt="Imagem de fundo com formato de onda" className="onda"></img>
                <Content>
                    <div className="ImagemLogo">                   
                        <img src={logo} alt="Imagem da logo no Nell Carmo composta pelas letras iniciais de seu nome acompanhadas da legenda Nell Carmo hairstylist." className="Logo"></img>
                        <h1>Você mulher que mora em São Paulo, a transformação que você procura está aqui!</h1>
                    </div>
                    <div className="Texto-button">
                        <img src={Nell} alt="Imagem do Nell Carmo vestindo terno branco com camisa preta por baixo do terno, segurando sua tesoura dourada apontada para a câmera." className="Nell"></img>
                        <div>
                        <p>Olá! Sou o <strong>Nell Carmo, Hairstylist profissional</strong> e chegou a sua hora de ser transformada! Você que há tempos não cuida de si mesma, quero te transformar e deixar você ainda mais linda!<br/>Confiança, autoestima, elegância e tantas outras qualidades que você merece estão a uma transformação de fazerem parte da sua vida!</p>
                        <div className="btn">
                        <button onClick={()=>openLink()}>Agende um horário</button>
                        </div>
                        </div>
                    </div>
                </Content>
            </Container>
        </>
    )
}

export default HomeWeb