import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Container } from './style';
import image1 from '../../assets/Carousel/trofeu1.webp';
import image2 from '../../assets/Carousel/trofeu2.webp';

function SliderCarousel (){

return(
<>
<Container>
<Carousel preventMovementUntilSwipeScrollTolerance={true} showThumbs={false} showStatus={false}>
    <div>
        <img src={image2} alt="Imagem dos troféis de premiação sobre corte de cabelo"></img>
    </div>
    <div>
        <img src={image1} alt="Imagem dos troféis de premiação sobre corte de cabelo"></img>
    </div>
</Carousel>
</Container>
</>
);
}
export default SliderCarousel;