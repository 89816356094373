import React from "react";
import { Container, Content } from "./style"
import relogio from "../../../assets/icons/relogio.svg";
import foguete from "../../../assets/icons/foguete.svg";
import microfone from "../../../assets/icons/microfone.svg";
import megafone from "../../../assets/icons/megafone.svg";



function StoryWeb(){

    return(
    <Container>
        <Content>
        <div className="Video-title">
            <h2>Conheça minha história</h2>
            <iframe width="690" height="360"
            src="https://www.youtube.com/embed/FAZIplzy6yg"
            title="YouTube video player" 
            frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture">
            </iframe>
        </div>
        <div className="Itens">
            <div className="Descricao">
                <div className="Image-text">
                <img src={relogio} alt="imagem de um relógio"></img>
                <h3>+ de 700 vidas transformadas!</h3>
                </div>
                <p>Especialista em corte Chanel e cabelos loiros com mais de 700 transformações por ano.</p>
            </div>
            <div className="Descricao">
                <div className="Image-text">
                <img src={foguete} alt="imagem de um foguete"></img>
                <h3>+ 20 anos de experiência</h3>
                </div>
                <p>No mercado desde os 16 anos de idade, quero levar toda essa experiência até voce!</p>
            </div>
            <div className="Descricao">
                <div className="Image-text">
                <img src={microfone} alt="imagem de um microfone"></img>
                <h3>+ de 50 Workshops</h3>
                </div>
                <p>Workshops nacionais e internacionas.</p>
            </div>
            <div className="Descricao">
                <div className="Image-text">
                <img src={megafone} alt="imagem de um megafone"></img>
                <h3>+ de 10 mil alunos</h3>
                </div>
                <p>Profissionais em Hairstylist e cabeleireiros em cursos e workshops.</p>
            </div>
        </div> 
        </Content> 
    </Container>
    
    )
}

export default StoryWeb;