import React from "react";
import { Container } from './style';
import SliderCarousel from "../../../components/ImageCarousel";

function WorkMobile() {
    function openLink() {
        return window.open('https://wa.me/5511964477939')
    }

    return (
        <>
            <Container id='WorkMobile'>
                <div className="curriculo">
                    <h2>Com mais de 20 anos de experiência no ramo, sou especialista em corte chanel e cabelos loiros!</h2>
                </div>

                <div className="bandeira">
                    <div className="curriculo">
                        <p>Tenho formação nacional e internacional nas academias:</p>

                        <div className="lista">
                            <ul>
                                <li>Llongueras, na Argentina</li>
                                <li>Tony&amp;Guy, na Inglaterra</li>
                                <li>Pivot Point, no Brasil</li>
                                <li>Mahogany, no Brasil</li>
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="textgold">
                    <p>Recentemente participei do<br></br> <strong>Gold Scissors Award</strong>, um prêmio de relevância internacional.</p>
                </div>
                <SliderCarousel />
                <div className="btn">
                    <button type="button" onClick={() => openLink()}>Agende um horário</button>
                </div>
            </Container>
        </>
    )
}

export default WorkMobile