import styled from "styled-components";
import * as colors from "../../../styles/colors";

export const Container = styled.div`
width: 100%;
height:100vh;
background:linear-gradient(${colors.Background1});
overflow-x: hidden;

`
export const Content = styled.div`
@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');
width:1100px;
height:100vh;
margin:0 auto;

h2{
    width:100%;
    padding: 0.25%;
    font-family: 'Akshar', sans-serif;    
    font-size: 2rem;
    line-height: 3rem;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
    display: flex;
    text-align: center;
    margin-left: 0;
    justify-content: left;
}

.Depoiments{
    height:50vh;
}

.Depoiments-Organizer{
    display:flex;
    flex-direction:row;
    margin-bottom:1%;
    
}

.first{
    border: ${colors.Primary0} solid;
    width: 49%;
    height:100%;
    margin: 2% auto;
    border-radius: 10px;
    border-width: 1.5px;
    background-color: rgba(112, 112, 112, 0.5);
    display:flex;
    flex-direction:column;

    padding:1% 1% 1% 1%;
}

.second{
    border: ${colors.Primary0} solid;
    width: 49%;
    height:70%;
    margin: 2% auto;
    border-radius: 10px;
    border-width: 1.5px;
    background-color: rgba(112, 112, 112, 0.5);
    display:flex;
    flex-direction:column;
    
    padding:1% 1% 1% 1%;


}
.quadroOne{
    display: flex;
    flex-direction: row;
    padding: 3% 12% 2.5% 2.5%;
    align-items: center;
}

.quadroOne p{
    color: ${colors.Light};
    font-size:1.4rem;
    line-height: 17.6px;
    font-family: 'Akshar', sans-serif;
    font-weight: 700;
}

.quadroOne img{
    width: 25px;
    height: 25px;
    margin:0 3% 0 7%;
}

.comentOne{
    padding: 1% 1.5%;
    color: ${colors.Light};
    font-size: 1.1rem;
    line-height: 1.6rem;
    font-family: 'PT Sans', sans-serif;
    font-weight:400;
}

.second h3{
    padding: 0 30px;
    font-family: 'Akshar', sans-serif;
    font-size: 16px;
    line-height: 17.6px;
    text-align: center;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
    margin-top: 10%;
}

.uppercase{
    text-transform: uppercase;
}

.Perguntas{
    width: 100%;
    padding:1% 3% 3% 3%;
    background-color: rgba(112,112,112,0.5);
    border-radius:15px;
    margin:1% auto;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:center;

    
}

.Question{
    padding-bottom:3%;
    padding-top:3%;
    font-weight:700;
    font-family: 'Akshar', sans-serif;
    font-size: 1.4rem;
    line-height: 17.6px;
    text-align: left;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
}

.Answer{
    color: ${colors.Light};
    font-size: 1.1rem;
    line-height: 1.2rem;
    font-family: 'PT Sans', sans-serif;
    font-weight:400;

}

.BoxPerguntas{
    display:flex;
    flex-direction:column;
    justify-content:left;
    align-items:center;
    width:100%;
    height:37vh;
}

.leftQuestion{
    width:35%;
    margin-right:5%;
    height:100%;

}

.rightQuestion{
    width:65%;
    display:flex;
    flex-direction:column;
    height:100%;
}


`