import styled from "styled-components";
import * as colors from "../../../styles/colors";

export const Container = styled.div`

width: 100%;
height: 100vh;
background:linear-gradient(${colors.Background2});
overflow-x: hidden;

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');

`

export const Content = styled.div`
width:1100px;
height:100vh;
display:flex;
flex-direction:column;
margin:0 auto;

.Video-Carousel{
width:100%;

}
.description{
width: 80%;
margin:0 auto;
height:55vh;
display:flex;
flex-direction:row;
align-items:center;
justify-content:center;

}
.descriptionContent{
    width: 80%;
    height: 50%;
    margin: 4% 4%;
    padding: 0% 2% 0% 2%;
    background-color: rgba(53 ,53 ,53 , 0.5);
    border: 2px solid ${colors.Primary0};
    border-radius:20px;
    display:flex;
    justify-content:center;
    align-items:center;
}
p{
    color: ${colors.Light};
    font-family: 'PT Sans', sans-serif;
    font-weight: 700;
    font-size: 1.3rem;
    line-height: 1.5rem;
    text-align: center;
}
`