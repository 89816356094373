import styled from "styled-components";
import * as colors from "../../../styles/colors";

export const Container = styled.div`

width: 100%;
min-height: 70vh;
max-height:150vh;
background:linear-gradient(${colors.Background1});
overflow-x: hidden;
justify-content:center;
display:flex;
flex-direction:column;

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');

h2{
    padding: 5% 5% 3% 5%;
    margin-left: 5%;
    width: 100%;
    font-family: 'Akshar', sans-serif;
    font-weight:700;
    font-size: 24px;
    line-height: 26.4px;
    text-align: left;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
}

.title h2{
    padding: 2%;
    display: flex;
    justify-content: center;
    margin: 7% 0%;
}

.Perguntas{
    width: 70%;
    padding:0% 5% 0% 5%;
    background-color: rgba(112,112,112,0.5);
    border-radius:15px;
    margin:0 auto;
    display:flex;
    flex-direction:column;
    align-items:left;
}
.Question{
    padding-bottom:3%;
    padding-top:5%;
    font-weight:700;
    font-family: 'Akshar', sans-serif;
    font-size: 16px;
    line-height: 17.6px;
    text-align: left;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
}
.Answer{
    padding-bottom:5%;
    font-weight:400;
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
    line-height: 20.7px;
    text-align: left;
    color:white;

}
.Icons{
    width:100%;
    height:10vh;
    padding-top: 3%;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-content:left;
    margin-left:10%;
}
a{
    padding-left:5%;
}
img{
    width:27px;
    height:27px;
}
`