import styled from "styled-components";
import * as colors from "../../../styles/colors";

export const Container = styled.div`
width: 100%;
max-height:200vh;
background: linear-gradient(${colors.Background1});
overflow-x:hidden;

@import url('https://fonts.googleapis.com/css2?family=Akshar:wght@700&family=PT+Sans&display=swap');

h2{
    font-family: 'Akshar', sans-serif;
    font-size:24px;
    line-height:26.4px;
    padding:10px;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
}
iframe{
    width:100%;
    margin:0 auto;
}

.titulo{
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

.Itens{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    align-items:center;
}
.Descricao{
    width:70%;
    height:25%;
    display:flex;
    flex-direction:column;
    margin:5% 10% 5% 10%;
}
.Image-text{
    width:100%;
    height:100%;
    justify-content:center;
    align-items:center;
    display:flex;
    flex-direction:row;
    margin-bottom:10px;
}
h3{
    font-family: 'Akshar', sans-serif;
    font-size:16px;
    line-height:17.6px;
    margin-left:6vw;
    color:${colors.Primary0};
    text-align:center;
}
p{
    padding: 0% 7%;
    font-family: 'PT Sans', sans-serif;
    color:white;
    text-align:center;
    line-height:20.7px;
    font-size:16px;
}

`