import styled from "styled-components";
import * as colors from "../../styles/colors";

export const Container= styled.div`
width:100%;
background: linear-gradient(${colors.Background2});
@font-face{
    font-family: Akshar;
    src: url(../../../assets/fonts/Akshar/Akshar-Bold.ttf);
    font-weight: 700;
}
h1{
    margin-left: 5%;
    font-family: Akshar;
    font-size:24px;
    line-height:26.4px;
    text-align: center;
    padding:20px 20%;
    background-image: linear-gradient(${colors.Gradient});
    -webkit-background-clip: text;
    color: transparent;
}
div{
    width:100%;
}
.control-prev, .control-next{
    background-color: black !important; 
}
iframe{
    height:90vh;
}
`