import React from "react";
import { Container, Content } from './style';
import SliderCarouselVideoWeb from "../../../components/VideoCarouselweb";

function WorkWeb() {

    return (
        <>
            <Container id='WorkWeb'>
              <Content>
                  <div className="Video-Carousel">
                    <SliderCarouselVideoWeb/>
                  </div>
                  <div className="description">
                      <div className="descriptionContent">
                          <p>Com mais de 20 anos de experiência no ramo, sou especialista em corte chanel e cabelos loiros!</p>
                      </div>
                      <div className="descriptionContent">
                          <p>Sou responsável também por ministrar workshops a nível nacional e internacional transformando mais de 10 mil alunos por ano!</p>
                      </div>
                  </div>
              </Content>
            </Container>
        </>
    )
}

export default WorkWeb